<template>
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">
      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <v-icon color="primary" size="25">{{ icon }}</v-icon>
        </v-avatar>
        <h2 class="mb-0">{{ title }}</h2>
      </div>

      <v-btn
        outlined
        color="primary"
        class="text-capitalize font-600"
        @click="() => add()"
        v-if="!disableAdd"
      >
        {{ addText }}
      </v-btn>
    </div>

    <slot name="header"></slot>
    
    <v-col cols="12" sm="12" md="12" class="my-5 me-1 align-center text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-col>

    <v-col v-for="item in items" :key="item[itemKey]" cols="12" v-else>
      <base-card>
        <slot name="item" :item="item" :edit="()=>edit(item)" :remove="()=>remove(item)" :loading="loading">
          <div class="pa-4 d-flex align-center flex-wrap">
            <slot name="content" :item="item"></slot>

            <div class="mr-3">
              <v-btn
                icon
                color="grey"
                :disabled="loading"
                @click="() => edit(item)"
                v-if="$listeners && $listeners.edit"
              >
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>

              <v-btn
                icon
                color="grey"
                :disabled="loading"
                @click="() => remove(item)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </slot>
      </base-card>
    </v-col>

    <!-- INICIO DE NUEVO FOOTER -->
    <v-toolbar class="my-5" flat color="transparent">
      <v-toolbar-title class="text-body-2 blue-grey--text">{{ $t("Grid.page") }} {{ page }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="rounded mr-4"
        outlined
        fab
        x-small
        tile
        @click="
          () => {
            if (page > 1) page--;
          }
        "
        :disabled="page <= 1"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        color="primary"
        class="rounded"
        outlined
        fab
        x-small
        tile
        @click="() => page++"
        :disabled="items.length < limit"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- FIN DE NUEVO FOOTER -->
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "mdi-circle",
    },
    addText: {
      type: String,
      default: "add-button",
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: "id",
    },
    url: {
      type: String,
      default: null,
    },
    get: {
      type: String,
      default: null,
    },
    post: {
      type: String,
      default: null,
    },
    put: {
      type: String,
      default: null,
    },
    delete: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: 8,
    },
    filters: {
      type: Object,
      default: () => new Object(),
    },
    filterOperators: {
      type: Object,
      default: () => new Object(),
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.limit);
    },
  },
  watch: {
    url: {
      immediate: false,
      handler() {
        this.page = 1;
        this.fetchData();
      },
    },
    get: {
      immediate: false,
      handler() {
        this.page = 1;
        this.fetchData();
      },
    },
    page: {
      immediate: false,
      handler() {
        this.fetchData();
      },
    },
  },
  data() {
    return {
      page: 1,
      sortby: "",
      orderby: {},
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;

      axios
        .get(this.getUrl(this.get || this.url))
        .then((res) => {
          this.items = _.get(res, "data.data", []);
        })
        .catch((err) => {
          this.items = [];
        }).finally(() => {
          this.loading = false;
        });
    },
    add(){
      this.$emit('add');
    },
    edit(item) {
      if (this.$listeners && this.$listeners.edit) {
        this.$emit("edit", item);
      } else {
        this.$console.error(`LIST => Warning 'edit' function is not implementet yet.`);
      }
    },
    remove(item) {
      if (this.$listeners && this.$listeners.delete) {
        this.$emit("delete", item);
      } else {
        this.$dialog({
          title: this.$t('dialog.confirm'),
          text: this.$t('dialog.msg1'),
          confirmation: true,
        }).then(() => {
          this.loading = true;

          axios
            .delete(
              `${this.getUri(this.url) || this.delete}/${item[this.itemKey]}`
            )
            .then(() => {

              this.$emit('deleted');

              this.fetchData();
            })
            .catch(() => {
              this.$dialog({
                title: "Error",
                text: "Hubo un error al intentar eliminar el registro seleccionado!",
                confirmation: true,
              });
            });
        });
      }
    },
    getUri(uri) {
      let arr = uri.split("?") || [];

      if (arr.length > 0) {
        return arr[0];
      } else {
        return uri;
      }
    },
    getUrl(u) {
      // VERIFICA QUE LA URL TENGA PARAMETROS PREDEFINIDOS
      let arr = u.split("?");
      let temp = "";

      if (arr.length > 1) {
        // CONCATENA LOS PARAMETROS DE PAGINACION A LOS PARAMETROS PREDEFINIDOS
        temp =
          arr[0] +
          "?" +
          arr[1] +
          "&page=" +
          this.page +
          "&limit=" +
          this.limit +
          "&sort_by=" +
          (this.sortby || "") +
          "&order_by=" +
          (this.orderby[this.sortby] ? "asc" : "desc") +
          "&" +
          this.getFilterParameters();
      } else {
        // UNICAMENTE ENVIA PARAMETROS DE PAGINACION
        temp =
          arr[0] +
          "?page=" +
          this.page +
          "&limit=" +
          this.limit +
          "&sort_by=" +
          (this.sortby || "") +
          "&order_by=" +
          (this.orderby[this.sortby] ? "asc" : "desc") +
          "&" +
          this.getFilterParameters();
      }

      return temp;
    },
    getFilterParameters() {
      let params = [];

      Object.keys(this.filters).map((i) => {
        if (
          this.filters[i] != undefined &&
          this.filters[i] != null &&
          this.filters[i] != ""
        ) {
          //if (this.filters[i] != undefined && this.filters[i] != null ) {
          let op = "";

          if (
            this.filterOperators[i] != undefined &&
            this.filterOperators[i] != null &&
            this.filterOperators[i] != ""
          ) {
            op = this.filterOperators[i];
          }

          if (op == "") {
            params.push(i + "=" + this.filters[i]);
          } else {
            params.push(i + "=" + op + ":" + this.filters[i]);
          }
        }
      });

      return params.join("&");
    }
  }
};
</script>